import React from 'react';
import { motion } from 'framer-motion';

function LanguageSelector({ currentLanguage, onLanguageChange }) {
  return (
    <div className="fixed top-4 right-4 z-50 flex space-x-2">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => onLanguageChange('en')}
        className={`p-2 rounded-full ${currentLanguage === 'en' ? 'bg-[#8000ff]/50' : 'bg-[#2A0E61]/50'}`}
      >
        <img
          src="/images/flags/en.png"
          alt="English"
          className="w-6 h-6 rounded-full"
        />
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => onLanguageChange('pt')}
        className={`p-2 rounded-full ${currentLanguage === 'pt' ? 'bg-[#8000ff]/50' : 'bg-[#2A0E61]/50'}`}
      >
        <img
          src="/images/flags/pt.png"
          alt="Português"
          className="w-6 h-6 rounded-full"
        />
      </motion.button>
    </div>
  );
}

export default LanguageSelector; 