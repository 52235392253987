import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CodeBracketIcon, CommandLineIcon, CpuChipIcon, ServerIcon } from '@heroicons/react/24/outline';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import ContactForm from './components/ContactForm';
import LogoCarousel from './components/LogoCarousel';
import Footer from './components/Footer';
import LanguageSelector from './components/LanguageSelector';

const translations = {
  en: {
    nav: {
      home: "Home",
      about: "About",
      projects: "Projects",
      contact: "Contact"
    },
    hero: {
      title: "INGRID LEAL",
      subtitle: "Full Stack Developer",
      description: "Passionate about creating innovative solutions and transforming ideas into reality through code."
    },
    about: {
      title: "MY ",
      description: "STACKS"
    },
    companys: {
      title: "Companies I've Worked With",
    },
    projects: {
      title: "My Stacks",
      project1: {
        title: "Back-end Developer",
        description: "PHP, Node.js, SQL, MySQL, Supabase"
      },
      project2: {
        title: "Front-end Developer",
        description: "JavaScript, React.js, Next.js, Tailwind CSS, Bootstrap, SCSS"
      },
      project3: {
        title: "Mobile Developer",
        description: "React Native"
      },
      project4: {
        title: "DevOps",
        description: "Docker, Git (GitHub), Versionamento"
      },
      project5: {
        title: "Automação e IA",
        description: "Integração de IA (OpenAI API), Web Services e Automação"
      },
      project6: {
        title: "Outras Competências",
        description: "SEO, Google Analytics, WordPress, Magento, Moodle"
      }
    },
    contact: {
      title: "CONTACT ME",
      name: "Name",
      email: "Email",
      message: "Message",
      submit: "Send Message",
      success: "Message sent successfully!",
      error: "Error sending message. Please try again.",
      namePlaceholder: "Enter your name",
      emailPlaceholder: "Enter your email",
      messagePlaceholder: "Enter your message",
      back: "Back"
    },
    footer: {
      rights: "All rights reserved."
    }
  },
  pt: {
    nav: {
      home: "Início",
      about: "Sobre",
      projects: "Projetos",
      contact: "Contato"
    },
    hero: {
      title: "INGRID LEAL",
      subtitle: "Desenvolvedora Full Stack",
      description: "Apaixonada por criar soluções inovadoras e transformar ideias em realidade através do código."
    },
    about: {
      title: "MINHAS",
      description: "STACKS"
    },
    companys: {
      title: "Empresas que já trabalhei",
    },
    projects: {
      title: "Minhas Stacks",
      project1: {
        title: "Back-end Developer",
        description: "PHP, Node.js, SQL, MySQL, Supabase"
      },
      project2: {
        title: "Front-end Developer",
        description: "JavaScript, React.js, Next.js, Tailwind CSS, Bootstrap, SCSS"
      },
      project3: {
        title: "Mobile Developer",
        description: "React Native"
      },
      project4: {
        title: "DevOps",
        description: "Docker, Git (GitHub), Versionamento"
      },
      project5: {
        title: "Automação e IA",
        description: "Integração de IA (OpenAI API), Web Services e Automação"
      },
      project6: {
        title: "Outras Competências",
        description: "SEO, Google Analytics, WordPress, Magento, Moodle"
      }
    },
    contact: {
      title: "ENTRE EM CONTATO",
      name: "Nome",
      email: "Email",
      message: "Mensagem",
      submit: "Enviar Mensagem",
      success: "Mensagem enviada com sucesso!",
      error: "Erro ao enviar mensagem. Por favor, tente novamente.",
      namePlaceholder: "Digite seu nome",
      emailPlaceholder: "Digite seu email",
      messagePlaceholder: "Digite sua mensagem",
      back: "Voltar"
    },
    footer: {
      rights: "Todos os direitos reservados."
    }
  }
};

function Home({ language, translations, setLanguage }) {
  const navigate = useNavigate();
  const [gradientPosition, setGradientPosition] = useState(0);
  const [text, setText] = useState('');
  const fullText = translations[language].hero.title;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setText('');
    setCurrentIndex(0);
  }, [language]);

  useEffect(() => {
    const interval = setInterval(() => {
      setGradientPosition((prev) => (prev + 1) % 100);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentIndex < fullText.length) {
      const timeout = setTimeout(() => {
        setText((prev) => prev + fullText[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, fullText]);

  const cardVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
      boxShadow: "0 0 20px rgba(99, 102, 241, 0.3)",
    },
    initial: {
      scale: 1,
      transition: { duration: 0.2 },
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white relative overflow-hidden flex flex-col">
      <LanguageSelector currentLanguage={language} onLanguageChange={setLanguage} />

      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full bg-[radial-gradient(circle_at_50%_50%,rgba(128,0,255,0.1),transparent_50%)] animate-pulse" />
        <div className="absolute w-full h-full">
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-gradient-to-r from-[#8000ff]/10 to-[#ff00ff]/10"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                width: `${Math.random() * 300 + 100}px`,
                height: `${Math.random() * 300 + 100}px`,
                transform: 'rotate(45deg)',
              }}
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.1, 0.2, 0.1],
              }}
              transition={{
                duration: Math.random() * 5 + 5,
                repeat: Infinity,
                ease: "linear",
              }}
            />
          ))}
        </div>
      </div>

      {/* Hero Section */}
      <section className="container mx-auto px-4 py-20 relative z-10 flex-grow">
        <div className="flex flex-col-reverse lg:flex-row items-center justify-center gap-8 lg:gap-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center lg:text-left lg:max-w-2xl"
          >
            <h1
              className="text-5xl md:text-7xl font-bold mb-6 relative"
              style={{
                background: `linear-gradient(90deg, #8000ff, #ff00ff)`,
                backgroundSize: '200% 100%',
                backgroundPosition: `${gradientPosition}% 0`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                animation: 'gradient 3s linear infinite',
              }}
            >
              {text}
            </h1>
            <h2 className="text-3xl md:text-4xl text-gray-300 mb-8 min-h-[48px]">
              {translations[language].hero.subtitle}
            </h2>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto lg:mx-0 mb-12">
              {translations[language].hero.description}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="relative lg:ml-4"
          >
            <div className="w-64 h-64 md:w-80 md:h-80 rounded-full overflow-hidden border-4 border-[#8000ff] relative z-10">
              <img
                src="/images/profile/caricatura.png"
                alt="Ingrid Leal"
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="absolute inset-0 rounded-full bg-gradient-to-r from-[#8000ff] to-[#ff00ff] blur-2xl opacity-30"
              style={{ transform: 'scale(1.1)' }}
            />
          </motion.div>
        </div>

        {/* Tech Stack */}
        <div className="relative max-w-6xl mx-auto px-4 py-20">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-16">
            <span className="text-white">{translations[language].about.title}</span>
            <span className="bg-gradient-to-r from-[#8000ff] to-[#ff00ff] bg-clip-text text-transparent"> {translations[language].about.description}</span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative z-10">
            {/* Left Column */}
            <div className="space-y-8">
              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <CodeBracketIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project1.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project1.description}</p>
                </div>
              </motion.div>

              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <CommandLineIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project2.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project2.description}</p>
                </div>
              </motion.div>

              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <CommandLineIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project3.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project3.description}</p>
                </div>
              </motion.div>
            </div>

            {/* Center Image */}
            <div className="hidden lg:flex items-center justify-center">
              <img
                src="/images/tech-stack.png"
                alt="Tech Stack Illustration"
                className="w-80 h-auto"
              />
            </div>

            {/* Right Column */}
            <div className="space-y-8">
              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <CpuChipIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project4.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project4.description}</p>
                </div>
              </motion.div>

              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <ServerIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project5.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project5.description}</p>
                </div>
              </motion.div>

              <motion.div
                variants={cardVariants}
                initial="initial"
                whileHover="hover"
                className="bg-[#2A0E61]/50 backdrop-blur-sm p-6 rounded-3xl border border-[#8000ff]/50"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="bg-[#8000ff] w-14 h-14 rounded-2xl flex items-center justify-center mb-4">
                    <ServerIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{translations[language].projects.project6.title}</h3>
                  <p className="text-gray-400">{translations[language].projects.project6.description}</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Logo Carousel */}
        <LogoCarousel language={language} translations={translations} />

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-center mt-12"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/contact')}
            className="relative px-8 py-3 rounded-full text-lg font-semibold overflow-hidden group duration-300 ease-in-out"
          >
            <span className="relative z-10 text-white">{translations[language].contact.title}</span>
            <div className="absolute inset-0 bg-gradient-to-r from-[#8000ff] to-[#ff00ff] opacity-0 group-hover:opacity-100 duration-300 ease-in-out" />
            <div className="absolute inset-0 bg-gradient-to-r from-[#8000ff] to-[#ff00ff] animate-pulse" />
          </motion.button>
        </motion.div>
      </section>

      <Footer language={language} translations={translations} />
    </div>
  );
}

function App() {
  const [language, setLanguage] = useState('pt');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home language={language} translations={translations} setLanguage={setLanguage} />} />
        <Route path="/contact" element={
          <div className="min-h-screen bg-gray-900 flex flex-col">
            <ContactForm language={language} translations={translations} />
            <Footer language={language} translations={translations} />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App; 