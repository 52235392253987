import React from 'react';
import { motion } from 'framer-motion';

function LogoCarousel({ language, translations }) {
  const logos = [
    {
      src: '/logos/caricanecas.png',
      alt: 'Caricanecas',
      width: 150
    },
    {
      src: '/logos/trainning.png',
      alt: 'Trainning',
      width: 150
    },
    {
      src: '/logos/toyshow.webp',
      alt: 'ToyShow',
      width: 150
    },
    {
      src: '/logos/trustwallet.png',
      alt: 'Code Web',
      width: 150
    }
  ];

  return (
    <div className="w-full overflow-hidden bg-gray-800/30 backdrop-blur-sm py-12 mt-20">
      <div className="max-w-6xl mx-auto px-4">
        <h3 className="text-2xl font-semibold text-center mb-10 text-gray-300">
          {translations[language].companys.title}
        </h3>
        <div className="relative">
          <motion.div
            className="flex space-x-16 items-center"
            animate={{
              x: [0, -1500],
            }}
            transition={{
              x: {
                duration: 20,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
              },
            }}
          >
            {/* First set of logos */}
            {logos.map((logo, index) => (
              <div
                key={index}
                className="flex items-center justify-center min-w-[150px]"
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  width={logo.width}
                  className="object-contain filter brightness-100 hover:brightness-125 transition-all duration-300"
                />
              </div>
            ))}
            {/* Duplicate set of logos for seamless loop */}
            {logos.map((logo, index) => (
              <div
                key={`duplicate-${index}`}
                className="flex items-center justify-center min-w-[150px]"
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  width={logo.width}
                  className="object-contain filter brightness-100 hover:brightness-125 transition-all duration-300"
                />
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default LogoCarousel; 