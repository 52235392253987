import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

function ContactForm({ language, translations }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      await emailjs.send(
        'YOUR_SERVICE_ID',
        'YOUR_TEMPLATE_ID',
        formData,
        'YOUR_PUBLIC_KEY'
      );
      setStatus({
        type: 'success',
        message: translations[language].contact.success
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setStatus({
        type: 'error',
        message: translations[language].contact.error
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-2xl mx-auto"
        >
          <div className="flex justify-start mb-8">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/')}
              className="relative px-6 py-2 rounded-full text-base font-semibold overflow-hidden group duration-300 ease-in-out bg-[#2A0E61] hover:bg-[#8000ff]/30 border border-[#8000ff]/50"
            >
              {translations[language].contact.back}
            </motion.button>
          </div>

          <h2 className="text-4xl font-bold mb-8 text-center bg-gradient-to-r from-[#8000ff] to-[#ff00ff] bg-clip-text text-transparent">
            {translations[language].contact.title}
          </h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                {translations[language].contact.name}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-[#2A0E61]/50 border border-[#8000ff]/50 rounded-lg focus:ring-2 focus:ring-[#8000ff] focus:border-transparent text-white"
                placeholder={translations[language].contact.namePlaceholder}
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                {translations[language].contact.email}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 bg-[#2A0E61]/50 border border-[#8000ff]/50 rounded-lg focus:ring-2 focus:ring-[#8000ff] focus:border-transparent text-white"
                placeholder={translations[language].contact.emailPlaceholder}
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                {translations[language].contact.message}
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full px-4 py-2 bg-[#2A0E61]/50 border border-[#8000ff]/50 rounded-lg focus:ring-2 focus:ring-[#8000ff] focus:border-transparent text-white"
                placeholder={translations[language].contact.messagePlaceholder}
              />
            </div>

            {status.message && (
              <div className={`p-4 rounded-lg ${
                status.type === 'success' ? 'bg-green-500/20 text-green-400' : 'bg-red-500/20 text-red-400'
              }`}>
                {status.message}
              </div>
            )}

            <div className="flex justify-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={isSubmitting}
                className="relative px-8 py-3 rounded-full text-lg font-semibold overflow-hidden group duration-300 ease-in-out bg-gradient-to-r from-[#8000ff] to-[#ff00ff] text-white disabled:opacity-50"
              >
                {isSubmitting ? '...' : translations[language].contact.submit}
              </motion.button>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
}

export default ContactForm; 